<template>
  <div>
    <v-container>
      <page-title :title="title"></page-title>
      <customForm
        v-if="initDone"
        :slotComponents="slotComponents"
        ref="customForm"
        :handlerSubmit="updateApi"
        :value="readData"
        :commonProps="{ stage, readApi, changeStage }"
      >
        <template v-slot:action="{ defaultKey }">
          <div>
            <div v-show="stage == 2" class="px-4 py-6">
              <paymentInterface
                ref="paymentInterface"
                :layout="0"
                :data="data"
                :ecpayHtmlArea="false"
                :afterPayForEcPay="setEcpayHtml"
              ></paymentInterface>
            </div>
          </div>

          <cartBtns
            :defaultKey="defaultKey"
            :changeStage="changeStage"
            :stage="stage"
            :storeId="storeId"
            :providerId="providerId"
          ></cartBtns>
        </template>
      </customForm>
      <div v-html="paymentInterfaceEcpayHtml"></div>
    </v-container>
    <PrimaryModal :show="request.switch">
      <v-card-title class="d-flex justify-center">
        <span class="pb-5">請先與 {{ layoutProviderName }} 建立合作</span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-center">
          <v-btn
            min-width="100px"
            color="primary"
            @click="navigateToProductList"
            >確認</v-btn
          >
        </div>
      </v-card-text>
    </PrimaryModal>
  </div>
</template>

<script lang="babel" type="text/babel">
import PrimaryModal from "components/modal/primaryModal.vue";
import customForm from "@/components/form/custom/customForm.vue";
import cartBtns from "./cartBtns.vue";
import paymentInterface from "@/components/payment/payment.vue";

export default {
  mixins: [],
  components: {
    PrimaryModal,
    customForm,
    cartBtns,
    paymentInterface,
  },

  data: () => ({
    stage: 1,
    initDone: false,
    data: {
      order_no: "",
    },
    paymentInterfaceEcpayHtml: "",
    request: {
      switch: false,
    },
  }),
  computed: {
    isRequest() {
      return this.$store.getters["store/isRequest"];
    },
    title() {
      if (this.stage == 0) return "購物車";
      if (this.stage == 1) return "購物車";
      if (this.stage == 2) return "訂單確認";
      return "訂單已送出";
    },
    providerId() {
      return this.$route.params.providerId;
    },
    storeId() {
      return this.$store.getters[`member/storeId`];
    },
    hasCooperated() {
      return this.$store.getters[`store/isCooperation`];
    },
    slotComponents() {
      return [
        {
          name: "info",
          values: ["*"],
          component: () => import("./info.vue"),
        },
      ];
    },
    readData() {
      return this.$store.getters["cart/cart"];
    },
    paymentTypeSelected() {
      return this.$refs.paymentInterface
        ? this.$refs.paymentInterface.paymentTypeSelected
        : null;
    },
    layoutProviderName() {
      return this.$store.getters[`base/layoutProviderName`];
    },
  },
  async created() {
    await this.beforeFormInit();
  },
  mounted() {},
  methods: {
    async beforeFormInit() {
      await Promise.all([this.readApi()]);
      this.initDone = true;
    },
    async readApi() {
      const res = await this.$api.collection.cartApi.read(
        this.storeId,
        this.providerId
      );
      this.$store.dispatch("cart/setCart", res);
      return res;
    },
    async updateApi(data = {}) {
      this.$root.gaLogEvent("商店_點擊_結帳");

      this.$store.dispatch("loading/active");

      try {
        if (!this.paymentTypeSelected) {
          return false;
        }
        data.paymentType = this.paymentTypeSelected;

        const newOrder = await this.$api.collection.orderApi.create(
          this.storeId,
          this.providerId,
          data
        );
        this.data.order_no = newOrder.order_no;

        const successMsg = "建立訂單成功";
        this.$snotify.success(successMsg);

        this.$refs.paymentInterface.toPay();
      } catch (err) {
        console.error(err);
        this.$snotify.error("建立訂單失敗");
        this.$store.dispatch("loading/close");
      }
    },
    setEcpayHtml(html) {
      this.paymentInterfaceEcpayHtml = html;
      this.$nextTick(() => {
        document.getElementById("ecpay-form").submit();
      });
    },
    changeStage(stage) {
      if (stage == 2 && !this.hasCooperated) {
        this.replaceToRegister()
      }
      this.stage = stage;
    },
    replaceToRegister() {
      const url = window.location.href;
      const origin = window.location.origin;
      this.$router.replace({
        name: "store-register",
        params: { providerId: this.providerId },
        query: { prevUrl: url.split(origin)[1] },
      });
    },
    navigateToProductList() {
      this.$router.replace({
        name: "store-register",
        params: { providerId: this.providerId },
      });
    },
  },
};
</script>
